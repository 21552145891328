/*CUSTOMIZATIONS:
-- display concorso_online button if field concorso_online is true
*/
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichText,
  RichTextArticle,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  descrizione: {
    id: 'descrizione',
    defaultMessage: 'Descrizione',
  },
  tipologia_bando: {
    id: 'tipologia_bando',
    defaultMessage: 'Tipologia del bando',
  },
  destinatari: {
    id: 'bando_destinatari',
    defaultMessage: 'Destinatari del bando',
  },
  ente: {
    id: 'bando_ente',
    defaultMessage: 'Ente erogatore',
  },
  compila_online: {
    id: 'compila_online',
    defaultMessage: 'Compila la domanda online',
  },
});

const BandoText = ({ content }) => {
  const intl = useIntl();
  return richTextHasContent(content?.text) ||
    content?.tipologia_bando ||
    content?.destinatari?.length > 0 ||
    content?.ente_bando?.length > 0 ||
    content.concorso_online === true ? (
    <RichTextArticle
      tag_id={'text-body'}
      title={intl.formatMessage(messages.descrizione)}
      show_title={true}
    >
      {/* DESCRIZIONE DEL BANDO */}
      {richTextHasContent(content?.text) && (
        <RichText title_size="h5" title={''} content={content?.text} />
      )}

      {/* CONCORSO ONLINE */}
      {content.concorso_online && (
        <p
          style={{
            marginTop: richTextHasContent(content?.text) ? '-2.5rem' : '0',
          }}
          className="mb-5"
        >
          <UniversalLink
            href="https://concorsi-gru.progetto-sole.it/exec/htmls/static/whrpx/login-concorsiweb/pxloginconc_grurer11401.html"
            target="_blank"
            className="btn btn-primary text-uppercase"
          >
            {intl.formatMessage(messages.compila_online)}
          </UniversalLink>
        </p>
      )}
      {/* TIPOLOGIA DEL BANDO */}
      {content?.tipologia_bando && (
        <>
          <h5>{intl.formatMessage(messages.tipologia_bando)}</h5>
          <span>{content.tipologia_bando.title}</span>
        </>
      )}
      {/* DESTINATARI DEL BANDO */}
      {content?.destinatari?.length > 0 && (
        <>
          <h5>{intl.formatMessage(messages.destinatari)}</h5>
          {content.destinatari.map((item, i) => (
            <p key={'destinatari-' + i}>{item.title}</p>
          ))}
        </>
      )}
      {/* ENTE DEL BANDO */}
      {content?.ente_bando?.length > 0 && (
        <>
          <h5>{intl.formatMessage(messages.ente)}</h5>
          {content.ente_bando.map((item, i) => (
            <span key={'ente_' + i}>
              {item}
              {i < content.ente_bando.length - 1 ? ', ' : ''}
            </span>
          ))}
        </>
      )}
    </RichTextArticle>
  ) : (
    <></>
  );
};

BandoText.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    tipologia_bando: PropTypes.shape({
      title: PropTypes.string,
      token: PropTypes.string,
    }),
    ente_bando: PropTypes.arrayOf(PropTypes.string),
    destinatari: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        token: PropTypes.string,
      }),
    ),
  }).isRequired,
};
export default BandoText;
